import React from 'react';
import { Card, Row } from 'antd';
import './style.scss';
import logo_png from '../../images/rem_logo.png';
const logo_box1 = () => {
    return(
        <Row className={'row_logo'}>
            <Card className={'background_logo'}>
                <img className={'logo'} src={logo_png}/>
                <h2 className={'titulo'} >Reem</h2>
            </Card>
        </Row>
    );
}

export default logo_box1;