
const Empresa = ( data ) => {
    return {
        id: data ? data.id : 'Desconocido',
        nombre: data ? data.nombre : 'Desconocido',
        direccion: data ? data.direccion : 'Desconocido',
        telefono: data ? data.telefono : 'Desconocido',
        whatsapp: data ? data.whatsapp : 'Desconocido',
        imagen: '',
        inventario: data ? data.inventario : []
    }
}

export default Empresa;