import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { NodeIndexOutlined, SendOutlined, ShopOutlined, ShoppingCartOutlined, UserOutlined,  } from '@ant-design/icons';
import { CardEstadistica } from './components';
import { apiEstadistica } from '@services';
import './style.scss';

const Estadistica = () => {

    const { Paragraph, Title } = Typography;

    const [ estadistica , setEstadistica ] = useState({
        data:{
            empresas:0,
            servicios:0,
            vehiculos:0,
            ordenes:0,
            transportadoras:0
        },
        loading:false,
    }); 

    useEffect( () => {
        requestEstadistica();
    },[]);

    const requestEstadistica = () => {
        setEstadistica({...estadistica,loading:true});
        apiEstadistica.obtenerEstadisticaPublica().then( (rslt) => {
            console.log(rslt);
            setEstadistica({data:rslt.data.obtenerEstadisticaPublica,loading:false});
        }).catch( (err) => {
            setEstadistica({...estadistica,loading:false});
            console.log(err);
        });
    }

    return(
        <Row className={'estadistica-container'}>
            <Col xs={24} md={12}>
                <Row gutter={[10,10]}>
                    <Col span={12}>
                        <CardEstadistica title={'Empresas'} Icon={ShopOutlined} value={estadistica.data.empresas} />
                    </Col>
                    <Col span={12}>
                        <CardEstadistica title={'Tranportadoras'} Icon={SendOutlined} value={estadistica.data.transportadoras}/>
                    </Col>
                    <Col span={12}>
                        <CardEstadistica title={'Domiciliarios'} Icon={UserOutlined} value={estadistica.data.vehiculos}/>
                    </Col>
                    <Col span={12}>
                        <CardEstadistica title={'Ventas'} Icon={ShoppingCartOutlined} value={estadistica.data.ordenes}/>
                    </Col>
                    <Col span={12}>
                        <CardEstadistica title={'Envios'} Icon={NodeIndexOutlined} value={estadistica.data.servicios}/>
                    </Col>
                    
                </Row>
            </Col>
            <Col xs={24} md={12} className={'descripcion-container'}>
                <Title>¡Bienvenido a Reem!</Title>
                <Paragraph>
                    Nuestro objetivo es digitalizar tu empresa. Si eres un empresario o 
                    domiciliario <strong><a>Reem</a></strong>te ayudará a conectar tus
                    sectores de importancia poniéndote en la mano la gestión de tu negocio;<br/><br/>
                    
                    <strong>Como empresa</strong> disfrutaras de una plataforma que te permitirá guardar tu 
                    inventario y solicitar domiciliarios que llevaran tus servicios hasta su destino. <br/><br/>
                    
                    <strong>Como domiciliario</strong> encontrarás en tu mano las solicitudes y oportunidades
                    de trabajo que necesitas directamente con los solicitantes y <strong>sin intermediarios.</strong> Ayudarte a
                    gestionar tus domicilios y mantenerlos en orden para que puedas mantener una visión clara de tu negocio es el objetivo
                    principal de nuestra plataforma.<br/><br/>

                    <strong>¡No es todo!</strong> <br/><br/>
                    
                    <strong>Reem Central</strong> es una aplicación dedicada
                    a las empresas de transporte y flotillas de mensajería. brindándote en la palma de tu mano el control
                    de tu negocio y mensajeros, adicionalmente ofrece un historial de los servicios brindados y un resumen mensual
                    sobre los ingresos que finalmente obtienes de toda tu operación
                </Paragraph>
            </Col>
        </Row>
    );
}

export default Estadistica;