
const Moneda = ( value ) => {
    try{
        return new Intl.NumberFormat('en-ES',{
            style:'currency',
            currency:'USD',
            maximumFractionDigits:0
        }).format(value);
    }catch(err){
        return 'Valor no definido';
    }
}

export default { Moneda };