import React,{ useEffect, useState } from 'react';
import { DispositivoContext } from '@context';
import { v4 as randomuuid } from 'uuid';
import jwt from 'jsonwebtoken';
import { Config } from '@utils'
// localStorage.removeItem('dispositivo');
const { jwtPublic } = Config;

const defaultUser = {
    nombre:'',
    data:null,
    accessToken:null,
    refreshToken:null,
}

const defaultContext = {
    dispositivoUID: randomuuid(),
    dispositivoInfo:navigator.userAgent,
    dispositivoToken: '',
    ip:'0.0.0.0',
    versionApp:'0.3',
    app_id:'Reem Store',
    created:new Date(),
    loading:false,
    user:defaultUser
}

const dispositivoItem = localStorage.getItem('dispositivo') ? JSON.parse(localStorage.getItem('dispositivo')) : defaultContext;


const DispositivoProvider = ( props ) => {
    const { children } = props;
    const [ dispositivo, setDispositivo ] = useState(dispositivoItem);

    const logout = () => {
        setDispositivo({
            ...dispositivo,
            user:defaultUser
        })
    }

    useEffect( () => {
        if(!localStorage.getItem('dispositivo')){
            console.log('Guardando storage');
            localStorage.setItem('dispositivo',JSON.stringify(dispositivo));
        } 
    },[]);

    useEffect( () => {
        // console.log(dispositivo,JSON.parse(localStorage.getItem('dispositivo')),JSON.stringify(dispositivo) !== localStorage.getItem('dispositivo'));
        if((JSON.stringify(dispositivo) !== localStorage.getItem('dispositivo'))){
            console.log('actualizando storage',dispositivo);
            localStorage.setItem('dispositivo',JSON.stringify(dispositivo));  
        }else{
            comprobar_refresh();
        } 
    },[dispositivo.user]);

    const comprobar_refresh = () => {
        const refresh = dispositivo.user.refreshToken;
        if(refresh){
            try{
                const decoded = jwt.verify(refresh,jwtPublic);
                // console.log(decoded);
            }catch(e){
                // console.log(e,dispositivo);
                // setDispositivo({
                //     ...dispositivo,
                //     user:defaultUser
                // });
            }
        }
    }

    return(
        <DispositivoContext.Provider value={{
            dispositivo:dispositivo,
            setDispositivo:setDispositivo,
            logout:logout
        }} >
            {children}
        </DispositivoContext.Provider>
    )

}

export default DispositivoProvider;