import React,{useState,useEffect} from 'react';
import { Row, Col, List } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { TextPriceFormatter } from '@utils';
import './style.scss';

const ListaCompra = ( {data,update} ) => {
    
    const { Moneda } = TextPriceFormatter;
    const [ items, setItems ] = useState({
        data:[]
    });

    useEffect( () => {
        let array = [];
        [...data].forEach((valueRoot,i) => {
            let exist = false;
            for(let a = 0 ; a < array.length ; a++){
                if(valueRoot.id == array[a].id){
                    exist = true;
                    array[a].cantidad += 1;
                    break;
                }
            }

            if(!exist){
                array.push({
                    ...valueRoot,
                    cantidad:1
                });
            } 
        });

        setItems({data:array});
    },[data]);

    return(
        <Row className={'container-lista'}>
            <Col span={24}>
                <List 
                dataSource={items.data}
                renderItem={(item) => {
                    return (
                        <List.Item 
                        actions={[
                            <p key={'valor'} className={'valor-item'}>{Moneda(item.valor*item.cantidad)}</p>, 
                            <DeleteFilled className={'delete-item'} onClick={(e) => {
                                e.stopPropagation();
                                let itemsTemps = [...data];
                                for(let i = 0 ; i < itemsTemps.length ; i++){
                                    if(itemsTemps[i].id == item.id){
                                        itemsTemps.splice(i,1);
                                        break;
                                    }
                                }
                                update(itemsTemps)
                            }} />
                        ]}>
                            <List.Item.Meta 
                            title={`${item.cantidad}x ${item.nombre}`}
                            description={''}
                            />

                        </List.Item>
                    );   
                }}/>
            </Col>
        </Row>
    )
}

export default ListaCompra;