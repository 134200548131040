import React, { useState } from 'react';
import { Perfil } from './views';
import { CarritoProvider } from '@context';

const Empresa = () => {
    return(
        <CarritoProvider >
            <Perfil />
        </CarritoProvider>
    )
}

export default Empresa;