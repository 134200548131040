import graph_base from './graph_base';

const get_web_info = ( data ) => {
    return graph_base.graph_request({
        query:`query obtenerWebQuery($token: String) {
            cargarInfoWeb(String: $token)
        }`,
        operationName:'obtenerWebQuery',
        variables:`{"token":"${data.token}"}`
    });
}

export default { get_web_info };