import React,{ useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import './style.scss'

const SelectorCategoria = () => {
    const history = useHistory();
    const location = useLocation();
    const [ categorias , setCategorias ] = useState([
        {
            id:1,
            nombre:'Tienda',
            enable:false
        },
        {
            id:2,
            nombre:'Restaurantes',
            enable:true
        },
        {
            id:3,
            nombre:'Farmacia',
            enable:true
        },
        {
            id:4,
            nombre:'Taller',
            enable:true
        },
        {
            id:5,
            nombre:'Ferreteria',
            enable:true
        }
    ]);

    const [ colores , setColores ] = useState(['#F0D94F','#F78257','#BA5AE0','#57B6F7','#77F07D']);


    return(
        <Row style={{justifyContent:'center'}} gutter={[8,8]}>
            {
                categorias.map( ( values ) => {
                    const color = values.enable ? colores[Math.floor(Math.random()*colores.length)] : 'gray';
                    console.log(color);
                    return (
                        <Col key={values.id} className={'col_categoria'} 
                        xs={{span:12}}
                        lg={{span:6}}>
                            <Card 
                            className={'card_categoria'}
                            style={{
                                background:`${color}`,
                                pointerEvents:`${values.enable ? 'all' : 'none'}`
                            }}
                            
                            bodyStyle={{
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                            }}
                            onClick={() => history.push(`${location.pathname}/${values.nombre}`)}>
                                <ShopOutlined style={{fontSize:28,marginRight:3}}/>
                                <p style={{fontSize:14,fontWeight:'bold',margin:0}}>{values.nombre}</p>
                            </Card>
                        </Col>
                    );
                })
            }
        </Row>
    );
}

export default SelectorCategoria;