import React, { useState } from 'react';
import { Card,Col,Image,Row } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import './style.scss'
const SelectorEmpresa = () => {
    const [ empresas , setEmpresas ] = useState([
        {
            id:1,
            nombre:"empresa_test"
        },
        {
            id:2,
            nombre:"empresa_test2"
        }
    ]);

    return(
        <Row style={{justifyContent:'center'}} gutter={[8,8]}>
            {
                empresas.map( (value) => {
                    return (
                        <Col key={value.id} className={'col_empresa'} 
                        xs={{span:12}}
                        lg={{span:6}}>
                            <Card className={'card_empresa'}>
                                <ShopOutlined className={'card_imagen'} style={{fontSize:28,marginRight:3}}/>
                                <p className={'btn_bottom_empresa_nombre'}>{value.nombre}</p>
                            </Card>
                        </Col>
                    );
                })
            }
        </Row>
        
    );
}

export default SelectorEmpresa;