import fetch from 'node-fetch';
import { Config } from '@utils'
import URL from 'url';
import queryString from 'query-string';
const { settings_default } = Config;

const conexion_base = ( body , metodo, headers_add ) => {
    return new Promise( (resolve,reject) => {
        let headers = {
            ...headers_add,
            'Content-Type':'application/json'
        }

        let queryStringTemp = '';

        
        let setting_params = {
            method:`${metodo}`,
            headers:headers,
            crossDomain:true,
        }

        switch(metodo){
            case 'POST':
                setting_params['body'] = JSON.stringify(body);
                break;
            case 'GET':
                queryStringTemp = queryString.stringify(body);
                break;
            default:
                console.warn('La petición debe ser GET o POST');
                return;
        }

        const url_query = `${settings_default.conexion_backend}${(queryStringTemp !== '' ? `?query=${queryStringTemp}` : '')}`;

        fetch(url_query,setting_params).then((rslt) => {
            resolve(rslt.json());
        }).catch((err)=> {
            reject(err);
        });
    });
}


export default { conexion_base };