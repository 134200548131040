import React, { useState } from 'react';
import { CarritoContext } from '@context';

const CarritoComprasProvider = ( props ) => {
    const { children } = props;
    const [ itemsCarrito, setCarrito ] = useState([]);


    return(
        <CarritoContext.Provider value={{
            data:itemsCarrito,
            update:setCarrito
        }}>
            {children}
        </CarritoContext.Provider>
    )
}

export default CarritoComprasProvider;