import graph_base from './graph_base';

const obtenerEstadisticaPublica = () => {
    return graph_base.graph_request({
        query:`query estadisticaQuery{
            obtenerEstadisticaPublica{
                empresas
                servicios
                vehiculos
                ordenes
                transportadoras
            }
        }`,
        operationName:`estadisticaQuery`
    })
}


export default { obtenerEstadisticaPublica }