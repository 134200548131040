import React, { useState } from 'react';
import { Row,Col, Divider, Typography,Button, Collapse } from 'antd';
import { TextPriceFormatter,DateFormatter } from '@utils'
import { PhoneFilled, StopFilled } from '@ant-design/icons';
import './style.scss';

const CompraItem = ( { item } ) => {
    const { Moneda } = TextPriceFormatter;
    const { FechaCompleta } = DateFormatter;
    const { Panel } = Collapse;
    const [ service, setService ] = useState( item ? item : {
        valor_servicio:null,
        valor_domicilio:null,
        sede:null,
        transportista:null,
        domiciliario:null,
        estado:null,
        objetos:[],
        fecha:null,
    });
    return(
        <Col span={24} className={'servicio-container'}>
            <Typography.Title level={5} className={'servicio-dato'}>Seguimiento ID:{<strong className={'value'}>{`${service.token ? service.token : 'Cargando...'}`}</strong>}</Typography.Title>
            <Typography.Text className={'servicio-dato'}>{`Estado del servicio:`}<strong className={'value'}>{`${service.estado ? service.estado : 'Cargando...'}`}</strong></Typography.Text>
            <Typography.Text className={'servicio-dato'}>{`Empresa:`}<strong className={'value'}>{`${service.sede ? service.sede : 'Cargando...'}`}</strong></Typography.Text>
            <Typography.Text className={'servicio-dato'}>{`Valor servicio:`}<strong className={'value'}>{` ${service.valor_servicio ? Moneda(service.valor_servicio) : `Cargando...`}`}</strong></Typography.Text>
            <Collapse className={'productos-container'}  ghost>
                <Panel header={'Mis productos'} key={1}>
                    {
                        service.objetos.map((value,i) => {
                            return(
                                <Typography.Text className={'servicio-dato'}>{`${value.cantidad}x ${value.nombre}`}<strong className={'value'}>{Moneda(value.valor)}</strong></Typography.Text>
                            );
                        })
                    }
                </Panel>
            </Collapse>
            <Divider />
            <Typography.Text className={'servicio-dato'}>{`Empresa de transporte:`}<strong className={'value'}>{`${service.transportista ? service.transportista : `Cargando...`}`}</strong></Typography.Text>
            <Typography.Text className={'servicio-dato'}>{`Domiciliario:`}<strong className={'value'}>{`${service.domiciliario ? service.domiciliario : `Cargando...`}`}</strong></Typography.Text>
            <Typography.Text className={'servicio-dato'}>{`Valor domicilio:`}<strong className={'value'}>{` ${service.valor_domicilio ? Moneda(service.valor_domicilio) : `Cargando...`}`}</strong></Typography.Text>
            <Divider />
            <Typography.Text className={'servicio-dato'}>{`Fecha:`}<strong className={'value'}>{`${service.createdAt ? FechaCompleta(service.createdAt) : `Cargando...`}`}</strong></Typography.Text>
            <Typography.Text className={'servicio-dato'}>{`Fecha:`}<strong className={'value'}>{`${service.updatedAt ? FechaCompleta(service.updatedAt) : `Cargando...`}`}</strong></Typography.Text>
            <Row>
                <Col  className={'actions-container'}>
                    <Button className={'servicio-contacto'} icon={<PhoneFilled />}  type={'primary'}>Contacto</Button>
                    <Button className={'servicio-contacto cancel'} icon={<StopFilled />}  type={'dashed'}>Cancelar servicio</Button>
                </Col>
            </Row>
            {/* <Typography.Title level={5}>Mi compra</Typography.Title> */}
            {/* {
                service.objetos.map((value,i) => {
                    return(
                        <Typography.Text className={'servicio-dato'}>{`${value.cantidad}x ${value.nombre}`}<strong className={'value'}>{Moneda(value.valor)}</strong></Typography.Text>
                    );
                })
            } */}
        </Col>
    )
}

export default CompraItem;