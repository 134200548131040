import React from 'react';
import { Row, Col, Form, Input,Typography } from 'antd'

const FormCompra = () => {

    const [form] = Form.useForm();

    const onFinish = ( values ) => {
        console.log(values);
    }

    const onFinishFailed = ( values ) => {
        console.log(values);
    }

    return(
        <Row>
            <Col span={24}>
                <Typography.Title level={2}>!Un ultimo paso!</Typography.Title>
                <Typography.Text>¿Donde requieres el servicio?</Typography.Text>
            </Col>
            <Col span={24}>
                <Form
                form={form}
                name={'form-buy'}
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                    <Form.Item name={'direccion'} label={'Dirección'}>
                        <Input placeholder={'Cra / Calle x # y - z'}/>
                    </Form.Item>
                    <Form.Item name={'comentarios'} label={'Comentarios adicionales'}>
                        <Input.TextArea placeholder={'¿Alguna recomendación sobre el servicio?'} rows={2}/>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );

}

export default FormCompra;