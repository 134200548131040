import React,{ useEffect, useState } from "react"
import { Producto as ProductoModel } from '../../models'
import { Row,Col,Image,Card,Grid,Button } from 'antd';
import { TextPriceFormatter } from '@utils';
import logo from '../../../../images/rem_logo.png';
import { CarritoContext } from '@context';
import { EyeOutlined, EyeFilled, ShoppingCartOutlined } from '@ant-design/icons';
import './style.scss';

const Producto = ( props ) => {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const { Moneda } = TextPriceFormatter;
    const { producto:productoIn, productos, ...rest } = props;
    const [ producto, setProducto ] = useState(productoIn ? ProductoModel(productoIn) : ProductoModel(null));
    const [ cantidad , setCantidad ] = useState( productos ? productos.filter((e) => (e.id == producto.id)).length : 0);
    const [ isMobil , setIsMobil ] = useState(false);
    const [ isOpen , setOpen ] = useState(false);


    useEffect( () => {
        setProducto(ProductoModel(productoIn));
    },[productoIn]);

    useEffect( () => {
        setCantidad(productos.filter((e) => (e.id == producto.id)).length);
    },[productos]);

    useEffect( () => {
        if(screens && screens.xs != isMobil) setIsMobil(screens.xs)
    },[screens.sm]);

    return (
        <Col {...rest} span={isMobil ? isOpen ?  24 : 24 : 8}>
            <Card className={'card-producto'}>
                <Row>
                    {
                        isOpen ? (
                            <Col span={24} className={'container-image'} style={isOpen ? {height:'140px'} : ''}>
                                {
                                    producto.url_imagen ? <Image className={'producto-imagen'} src={producto.url_imagen} width={'100%'}/> : (
                                        <div className={'no-image'}>
                                            <img src={logo} className={'logo-reem-producto'} />
                                        </div>
                                    )
                                }
                            </Col>
                        ) : ''
                    }
                    
                    <Col span={24} onClick={() => setOpen(!isOpen)}>
                        <Row className={'container-info'}>
                            <Col span={24}>
                                <p style={{margin:0}}>{producto.nombre}</p> 
                            </Col>
                            {
                                isOpen ? (
                                    <Col span={24}>
                                        <p className={'info-descripcion'} style={{margin:0}}>{producto.descripcion}</p> 
                                    </Col>
                                ) : ''
                            }
                            <Col span={24}>
                                <p style={{margin:0}}>{Moneda(producto.valor)}</p> 
                            </Col>
                            <CarritoContext.Consumer>
                                { context => {
                                    const items = context.data;
                                    const cant = !items ? 0 :  items.filter((e) => (e.nombre == producto.nombre)).length;
                                    return(
                                    <>
                                        <Button onClick={(e)=>{
                                            e.stopPropagation();
                                            context.update([...items,producto]);
                                        }} className={['btn-agregar', cant > 0 ? 'btn-focus' : '']} type={'dashed'} icon={<ShoppingCartOutlined className={'btn-icon'}/>} >
                                            Agregar { cant > 0 ? `(${cant})` : '' }
                                        </Button>
                                    </>
                                    )
                                }}
                            </CarritoContext.Consumer>
                            
                            { isOpen ? <EyeFilled className={'button-inspeccionar'}/> : <EyeOutlined className={'button-inspeccionar'}/>  }
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Col>
    )
}

export default Producto;