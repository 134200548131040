import React from 'react';
import { Col, Row } from 'antd';
import { Route, Switch } from 'react-router-dom';

import { Logo1 } from '../../../../components/index';
import { SelectorCiudad } from '../../components';
import { BuscadorCategorias } from '../../'
import './style.scss';

const Buscador = () => {


    return(
        <Row
        className={"contenedor_buscador"}>
            <Col
            xs={{span:23}}
            sm={{span:12}}
            className={'background_buscador'}>
                <Logo1/>
                <h2>Encuentra las empresas<br/>de tu ciudad</h2>

                <Switch>
                    <Route exact path="/buscador" component={SelectorCiudad}/>
                    <Route path="/buscador/:ciudad" component={BuscadorCategorias}/>
                </Switch>
            </Col>
        </Row>
    );
}

export default Buscador;