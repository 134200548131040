import 'bootstrap/dist/css/bootstrap.min.css';
import { Main } from '@views';
import { DispositivoProvider, DispositivoContext } from '@context';
import React, { useContext } from 'react';
import 'antd/dist/antd.css'

const App = () => {
  
  
  return (
      <DispositivoProvider>
        <Main/>
      </DispositivoProvider>
  );
}

export default App;


