import React, { useEffect, useState } from 'react';
import { Row,Col,Typography } from 'antd';
import { CompraItem } from './components'
import './style.scss';

const CarritoSeguimiento = () => {

    const [ compras, setCompras ] = useState({
        data:[
            {
                id:1,
                valor_servicio:11000,
                valor_domicilio:1200,
                sede:'Loncheria',
                transportista:'DomiTest',
                domiciliario:'Gilberto',
                estado:'En espera',
                token:'wdstr32',
                objetos:[
                    {
                        id:1,
                        nombre:'Chuleta',
                        valor:10000,
                        cantidad:1,
                    }
                ],
                createdAt:new Date(),
                updatedAt:new Date(),
            },
            {
                id:2,
                valor_servicio:11000,
                valor_domicilio:1200,
                sede:'Loncheria',
                transportista:'DomiTest',
                domiciliario:'Gilberto',
                estado:'En espera',
                token:'wdst332',
                objetos:[
                    {
                        id:1,
                        nombre:'Chuleta',
                        valor:10000,
                        cantidad:1,
                    }
                ],
                createdAt:new Date(),
                updatedAt:new Date(),
            }
        ],
        loading:false
    });

    useEffect(() => {

    },[])



    return(
        <Row>
            {
                compras.data.map( (value,i) => {
                    return(
                        <CompraItem key={i} item={value} />
                    );
                })
            }    
        </Row>
    );
}

export default CarritoSeguimiento;