import React from 'react';
import { Row, Col, Typography } from 'antd';
import logo from '@images/rem_logo.png';
import './style.scss';

const Pricing = () => {
    const { Text, Title, Paragraph } = Typography;
    return(
        <Row className={'pricing-container'}>
            <Col xs={24} sm={12} xl={12}>
                <Title level={2}>Crezcamos juntos</Title>
                <Paragraph>
                    Queremos digitalizar tu negocio con una plataforma que 
                    ayude al empresario a gestionar su negocio; conectándolo con 
                    los actores importantes de su entorno para su envió y su compra.
                </Paragraph>
                
                <Title level={3}>Suscríbete</Title>
                <Paragraph>
                    Costo: <strong className={'cost-text'}>$Gratis</strong>/mes
                </Paragraph>

                <Title level={3}>Quiero apoyar el proyecto</Title>
                <Paragraph>
                    Si deseas contribuir un poco con el proyecto, primero que todo: <br/><br/> 
                    
                    <strong>¡Mil Gracias!</strong> <br/><br/>


                    Trabajamos con todo el corazón en realizar esta plataforma para ayudar a 
                    nuestro país, con la esperanza que todos esos sueños empresariales; 
                    grandes y pequeños, puedan salir adelante.
                </Paragraph>
                <Paragraph>
                    <strong>Donación Nequi:</strong><br/>
                    Teléfono: <strong>3183907895</strong>
                </Paragraph>
            </Col>
            <Col xs={24} sm={12} xl={12}>
                <img src={logo} className={'logo-landing'} alt={'reem-logo'}/>
            </Col>
        </Row>
    );

}

export default Pricing;