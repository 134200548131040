const Producto = ( data ) => {
    return {
        id: data ? data.id : '',
        nombre: data ? data.nombre : '',
        descripcion: data ? 'Fino Lomo Viche acompañado de arroz, papaz y ensalada.' : '',
        valor: data ? data.valor : '',
        url_imagen: data ? 'https://cdn2.cocinadelirante.com/sites/default/files/styles/gallerie/public/images/2019/08/chuletas-de-cerdo-en-salsa-de-miel.jpg' : ''
    }
}

export default Producto;