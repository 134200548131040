import conexion from './conexion_base';
import graphql_estadistica from './graphql/estadisticas';

const { conexion_base } = conexion;

const obtenerEstadisticaPublica = () => {
    return new Promise( (resolve,reject) => {
        const obtenerEstadistica = graphql_estadistica.obtenerEstadisticaPublica();

        conexion_base(obtenerEstadistica,'POST').then( (rslt) => {
            resolve(rslt);
        }).catch( (err) => {
            console.log(err);
            reject(err);
        });
    });
    
}


export default { obtenerEstadisticaPublica };