import React, { useEffect, useState } from 'react';
import { Row,Col,Image,Menu } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import './style.scss';
const { SubMenu } = Menu;

const Sider = ( props ) => {
    const { categorias:categoriasIn } = props;
    const [ categorias, setCategorias ] = useState(categoriasIn);

    // useEffect( () => {
    //     console.log(categorias);
    // },[]);

    useEffect( () => {
        setCategorias(categoriasIn);
    },[categoriasIn]);

    return(
        <Row className={'container-menu'}>
            <Col span={24}>
                <h3 className={'menu-title'}>Categorias</h3>
                <Menu 
                mode="inline"
                className={'menu-scroll'}
                >
                    {
                        categorias && categorias.map( ( valor ) => (
                            <Menu.Item key={valor}>{valor}</Menu.Item>
                        ))
                    }
                </Menu>
            </Col>
        </Row>
    );
}

export default Sider;