import React,{ useEffect, useState } from 'react';
import { Button, Divider, List, Row, Col } from 'antd';
import { TextPriceFormatter } from '@utils'
import { CarritoContext } from '@context';
import { DeleteFilled } from '@ant-design/icons';
import { CarritoLista,CarritoSeguimiento } from './views'
import './style.scss'

const CarritoCompra = ( props ) => {
    const carritoContext = React.useContext(CarritoContext);
    const { data, update } = carritoContext;
    
    const [ paso , setPaso ] = useState('compra');
    

    return(
        paso == 'compra' ? <CarritoLista setPaso={setPaso} /> : <CarritoSeguimiento />
    );
}

CarritoCompra.defaultProps  = {
    dataIn:[]
}


export default CarritoCompra;