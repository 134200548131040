import graphWeb from './graphql/app_web';
import conexionBase from './conexion_base';

const { get_web_info } = graphWeb;
const { conexion_base:conexion } = conexionBase;

const obtener_empresa_info = ( data ) => {
    return new Promise( (resolve,reject) => {
        const query = get_web_info( { token:data } );

        conexion(query,'GET').then((rslt) => {
            resolve(rslt);
        }).catch((err) => {
            reject(err);
        })    
    });
}


export default { obtener_empresa_info }