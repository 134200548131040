import React from 'react';
import { Row,Col,Typography } from 'antd';
import './style.scss';
const Footer = () => {
    const { Text,Title } = Typography;
    return(
        <Row className={'footer-container'}>
            <Col span={24}>
                <Title level={5}>Reem APP</Title>
                <Title level={5}>Cali - Valle del Cauca 2020</Title>
            </Col>
        </Row>
    );

}

export default Footer;