import React, { useContext, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Empresa, InDev, LandingEmpresas  } from '@views';
import { Buscador } from '@views/Buscador';
import { DispositivoProvider, DispositivoContext } from '@context';
import { getToken, onMessageListener }  from '../../firebase';

const Main = () => {


    const dispositivoContext = useContext(DispositivoContext);
    const { dispositivo, setDispositivo } = dispositivoContext;
        
    useEffect( () => {
        getToken().then( (rslt) => {
            setDispositivo({
                ...dispositivo,
                dispositivoToken:rslt
            })
        }).catch( (e) => {
            console.log(e);
        });
    },[]);
    

    onMessageListener().then( (rslt) => {
        console.log(rslt);
    }).catch( (err) => {
        console.log(err);
    });

    return(
        <Router>
          <Switch>
            <Route path="/buscador" component={Buscador}/>
            <Route path="/empresa/:empresa" component={Empresa}/>
            <Route path="/" component={LandingEmpresas}/>
          </Switch>
        </Router>
    );
}

export default Main;