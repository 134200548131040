import React, { useEffect, useState } from 'react';
import { Row,Col,Image,Layout,Card,Divider, Spin,Select } from 'antd';
import { WhatsAppOutlined,PhoneOutlined } from '@ant-design/icons';
import { Content, Header } from 'antd/lib/layout/layout';
import { apiWeb } from '@services'
import { Header as Reemheader } from '@components'
import { NavInventario,ProductoItem } from '../../components';
import { Empresa as EmpresaModel } from '../../models'
import './style.scss'

const Perfil = ( props ) => {
    const { Option } = Select;
    const { Sider } = Layout;
    const { empresaIn } = props;
    const [ filtroCategoria, setFiltroCategoria ] = useState(null);
    const [ empresa , setEmpresa ] = useState({
        loading:false,
        data:EmpresaModel(),
        categorias:[],
    });

    useEffect( () => {
        setEmpresa({...empresa,loading:true});
        apiWeb.obtener_empresa_info('sQ47MyjQ6o0K1wOMSn4b3').then( (rslt) => {
            const jsonEmpresa = rslt.data.cargarInfoWeb; 
            const inventario = jsonEmpresa.inventario;
            const categorias = [];

            for(let i = 0 ; i < inventario.length ; i++){
                if(!inventario[i].tags) continue;
                const catArray = JSON.parse(inventario[i].tags);
                for(let c = 0 ; c < catArray.length ; c++){
                    const catItem = catArray[c];
                    if(!categorias.includes(catItem)) categorias.push(catItem);
                }
            }

            // console.log(inventario);

            setEmpresa({loading:false,data:EmpresaModel(jsonEmpresa),categorias:categorias});
        }).catch( (err) => {
            console.log(err);
            setEmpresa({loading:false,data:EmpresaModel()});
        });
    }, [] );

    return(
        <Layout className={'reem-layout'}>
            <Reemheader />
            <Layout>
                <Sider 
                width={200}
                breakpoint={'lg'}
                collapsedWidth={0}
                trigger={null}
                >
                    <NavInventario categorias={empresa.categorias}/> 
                </Sider>
                <Layout>
                    <Content className={'reem-main-contenedor'}>
                        <Row style={{justifyContent:'center'}}>
                            <Col
                            lg={16}>
                                <Row className={'reem-empresa-info'}>
                                    {
                                        empresa.imagen != '' ? (
                                            <Col span={24}>
                                                <Image width={'100%'} height={'180px'}
                                                className={'imagen_top_empresa'}/>
                                            </Col>
                                        ) : '' 
                                    }
                                    
                                    <Col span={24} className={'perfil-contenedor_datos'}>
                                        <div className={'nombre_top_empresa'}>
                                            {
                                                empresa.loading ? <Spin spinning={true} >Cargando</Spin> : (
                                                    <> 
                                                        <h3 className={'nombre_empresa'}>{empresa.data.nombre}</h3>
                                                        <h4 className={'nombre_direccion'}>{empresa.data.direccion}</h4>
                                                        <div className={'contenedor_telefonos'}>
                                                            {empresa.data.telefono != '' && ( <p className={'empresa_telefonos'}><PhoneOutlined style={{marginRight:'5px'}}/>{empresa.data.telefono}</p> ) || ''}
                                                            {empresa.data.whatsapp != '' && ( <p className={'empresa_telefonos'}><WhatsAppOutlined style={{marginRight:'5px'}}/>{empresa.data.whatsapp}</p> ) || ''}
                                                        </div>
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{
                                    marginTop:10,
                                    marginBottom:10
                                }}>
                                    <Col span={24}>
                                        <Layout style={{padding:10}}>
                                            <Content>
                                                <Row style={{margin:0}} gutter={[16, 16]} >
                                                    <Col span={24}>
                                                        <h3 className={'header-perfil_menu'}>Productos</h3>
                                                        <Select
                                                        className={'header-buscador_categorias'}
                                                        showSearch
                                                        onChange={(e)=>setFiltroCategoria(e)}
                                                        value={filtroCategoria}
                                                        loading={empresa.loading}
                                                        placeholder={'Categorias'}>
                                                            <Option value={null}>Todos</Option>
                                                            {
                                                                empresa.categorias && empresa.categorias.map( (item,index) => {
                                                                    return(
                                                                        <Option key={`${item}_${index}`} value={item}>{item}</Option>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </Col>
                                                    <Divider style={{
                                                        marginTop:0,
                                                    }} orientation={"left"}></Divider>
                                                    
                                                    <Col span={24}>
                                                        <Spin className={'perfil-inventario_loading'} tip={'Cargando inventario'} spinning={empresa.loading}/>
                                                    </Col>
                                                    {
                                                        [...empresa.data.inventario.filter((value)=>{
                                                            if(!value.tags) return false;
                                                            const tags = [ ...JSON.parse(value.tags)];
                                                            return filtroCategoria ? tags.includes(filtroCategoria) : true;
                                                        })].map( ( value,index ) => (
                                                            <ProductoItem  key={index} producto={value} productos={empresa.data.inventario} /> 
                                                        ))
                                                    }
                                                    
                                                </Row>
                                            </Content>
                                        </Layout>
                                    </Col>
                                </Row>                
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
        
    );
}

Perfil.defaultProps = {
    empresaIn:{
        id:0,
        nombre:'',
        direccion:'',
        telefono:'',
        whatsapp:'',
        imagen: '',
        inventario:[],
    }
}

export default Perfil;