import React,{ useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';

const { Option } = Select;

const SelectorCiudad = () => {

    const history = useHistory();
    const location = useLocation();
    const [ buscador , setBuscador ] = useState('');
    const [ ciudades , setCiudades ] = useState([
        {
            id:'1',
            nombre:'Popayan'
        }
    ]);

    const getCiudadRaw = ( { id } ) => {
        for(let i = 0 ; i < ciudades.length ; i++){
            console.log(ciudades[i].id,id)
            if(ciudades[i].id == id) return ciudades[i];
        }
        return null;
    }

    const onChange = (value) => {
        console.log(`selected ${value}`);
        const ciudad = getCiudadRaw({ id:value });
        setBuscador( ciudad ? ciudad.nombre : '' );
        history.push(`/buscador${ ciudad ? `/${ciudad.nombre}` : ''}` )
    }
    
    const onBlur = () => {
        console.log('blur');
    }
    
    const onFocus = () => {
        console.log('focus');
    }
    
    const onSearch = (val) => {
        console.log('search:', val);
    }

    return(
        <Select 
        showSearch
        className={'selector_ciudad'}
        style={{ width: 200}}
        defaultValue=''
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        value={buscador}
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }>
            <Option style={{color:'gray'}} value="">Busca tu ciudad</Option>
            {
                ciudades.map( ( value ) => {
                    return (
                        <Option key={value.id} value={value.id}>{value.nombre}</Option>
                    );
                })
            }
        </Select>
    )
}

export default SelectorCiudad;