import React,{ useEffect, useState } from 'react';
import { Button, Divider, List, Row, Col } from 'antd';
import { TextPriceFormatter } from '@utils'
import { CarritoContext } from '@context';
import { DeleteFilled } from '@ant-design/icons';
import { FormCompra,ListaItems } from './components'
import './style.scss'

const CarritoLista = ( { setPaso:setPasoRoot } ) => {
    const carritoContext = React.useContext(CarritoContext);
    const { data, update } = carritoContext;
    const { Moneda } = TextPriceFormatter;
    const [ paso, setPaso ] = useState('lista');

    const onHandlerBuy = () => {
        switch(paso){
            case'lista':
                setPaso('compra');
            break;
            case'compra':
                setPasoRoot('seguimiento');
            break;
        }
    }

    const onHandlerList = () => {
        setPaso('lista');
    }

    return(
        <Row className={'contenedor-carshopping'}>
            <Col span={24}>
                {
                    paso == 'lista' ? <ListaItems data={data} update={update}/> : <FormCompra />
                }
            </Col>
            <Divider />
            <Col  className={'valores'} span={24}>
                <CarritoContext.Consumer>
                    {   
                        context => {
                            const data = context.data;
                            let valorTotal = 0;
                            [ ...data ].forEach( e => valorTotal += e.valor);
                            return (
                                <>
                                    <p>Productos: <strong>{data.length}</strong></p>
                                    <p>Valor: <strong>{Moneda(valorTotal)}</strong></p>
                                </>
                            )
                        }
                    }
                </CarritoContext.Consumer>
            </Col>
            <Divider />
            <Col className={'container-acciones'} span={24}>
                <Button type={'default'} onClick={onHandlerList}>Cancelar</Button>
                <Button type={'primary'} disabled={data.length == 0} onClick={onHandlerBuy}>{paso == 'lista' ? 'Compra' : 'Confirmar Compra'}</Button>
            </Col>
        </Row>
    );
}

CarritoLista.defaultProps  = {
    dataIn:[]
}


export default CarritoLista;