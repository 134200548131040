import firebase from 'firebase/app';
import 'firebase/messaging';
import { Config } from './utils';

firebase.initializeApp(Config.firebase_config);


const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
    return messaging.getToken({vapidKey:Config.vapidPublic}).then( (e) => {
        return e;
    }).catch( (e) => {
        return null;
    });
}

export const onMessageListener = () => new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        }
    );
});