import React,{ useContext, useState } from 'react';
import { Popover, Layout, Button,Spin } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { LoginForm, RegistroForm, CarritoCompra, MiPerfil } from '@views';
import { DispositivoContext } from '@context';
import { CarritoContext } from '@context';
import logo_png from '@images/rem_logo.png'
import './style.scss';
const Header = ( props ) => {
    const { Header } = Layout;
    const carritoContext = React.useContext(CarritoContext);
    const { data } = carritoContext || { data:null };
    const [ etapaUser , setEtapaUser ] = useState('login'); 
    const dispositivoContext = useContext(DispositivoContext);
    const { dispositivo } = dispositivoContext;

    return(
        <Header className={'reem-header'}>
            <img  height={'100%'} src={logo_png} alt={'reem-logo'}/>
            <h3>Reem</h3>
            {
                carritoContext && <Popover 
                placement={'topLeft'}
                trigger={"click"}
                title={'Carrito Compras'}
                content={CarritoCompra}
                >
                    <Button shape={'round'} type={'default'} className={`shopping-btn ${data.length > 0 && 'shopping-focus'}`} icon={<ShoppingCartOutlined className={`shopping-icon`} />}>{data.length > 0 && data.length}</Button>
                </Popover>
            }
            {/* <Popover 
            placement={'topLeft'}
            trigger={"click"}
            title={ dispositivo.user.data ? 'Mi Perfil' : etapaUser === 'login' ? 'Login' : 'Registro'}
            content={ dispositivo.user.data ? <MiPerfil /> : etapaUser === 'login' ? <LoginForm etapaUser={etapaUser} setEtapaUser={setEtapaUser}/> : <RegistroForm etapaUser={etapaUser} setEtapaUser={setEtapaUser} /> }>
                <Button shape={'round'} type={'default'} className={`profile-btn${!carritoContext ? '-right' : ''}`} >
                    <Spin spinning={dispositivo.loading} >
                        { dispositivo.user.data ? dispositivo.user.nombre : 'Ingresar'}
                    </Spin>
                </Button>
            </Popover> */}
        </Header>
    );
}

export default Header;