import React from 'react';
import { Row,Col } from 'antd';
import { Header as Reemheader } from '@components'
import { Pricing,Estadistica,Apps,Footer } from './components';
import './style.scss';
const Landing = () => {



    return(
        <Row className={'landing-container'}>
            <Col span={24}>
                <Reemheader />
            </Col>
            <Col span={24} className={'info-container'}>
                <Pricing/>
                <Estadistica/>
                <Apps/>
                <Footer />
            </Col>
        </Row>
    );

}

export default Landing;