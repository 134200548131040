const settings_default = {
    conexion_backend: process.env.REACT_APP_ISLOCAL === "true" ? process.env.REACT_APP_LOCAL_API : process.env.REACT_APP_BACKEND_API,
}

const vapidPublic = process.env.REACT_APP_VAPID_PUBLIC;

const firebase_config = {
    apiKey: "AIzaSyBD0CP2gfZIpnV7V-0cU3lfig2-ph9MrUY",
    authDomain: "mipequeapp-278903.firebaseapp.com",
    databaseURL: "https://mipequeapp-278903.firebaseio.com",
    projectId: "mipequeapp-278903",
    storageBucket: "mipequeapp-278903.appspot.com",
    messagingSenderId: "1013404272281",
    appId: "1:1013404272281:web:a1a49922b7885e93abda08",
    measurementId: "G-QF8ZFDQR1H"
}

const jwtPublic = process.env.REACT_APP_JWT_PUBLIC;
export default { settings_default,firebase_config,vapidPublic,jwtPublic }