import React from 'react';
import { Card } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { SelectorCategoria, SelectorEmpresa } from '../../components';
import { InDev } from '../../../InDev'

const VisorCategorias = () => {
    return(
        <Switch>
            <Route exact path="/buscador/:ciudad"  component={SelectorCategoria}/>
            <Route path="/buscador/:ciudad/:categoria" component={SelectorEmpresa}/>
        </Switch>
    );
}

export default VisorCategorias;