import React from 'react';
import PropTypes from 'prop-types';
import { Row,Col,Card,Typography } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import './style.scss';
import { Component } from 'react';

const CardEstadistica = ( props ) => {

    const { Icon,title,value } = props;
    const { Title, Text } = Typography;
    return(
        <Card className={'card-estadistica-container'}>
            <Icon className={'icon'}/>
            <Text className={'value'}>{value}</Text>
            <Title className={'title'} level={3}>{title}</Title>
        </Card>
    );
}

CardEstadistica.defaultProps = {
    Icon:ShopOutlined,
    title:'titulo',
    value:0
}

CardEstadistica.propTypes = {
    Icon:PropTypes.object,
    title:PropTypes.string,
    value:PropTypes.number
}

export default CardEstadistica;