import React from 'react';
import { Row,Col,Image,Typography, Button } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons'
import { ReemScreenShot1,ReemScreenShot2 } from '@images'
import './style.scss';

const Apps = () => {
    const { Title, Text, Paragraph } = Typography;

    return(
        <Row className={'apps-container'} gutter={[10,10]}>
            <Col xs={24} md={12}>
                <Title level={1}>Reem</Title>
                <Image src={ReemScreenShot1}/>
                <Paragraph>
                    Si eres un <strong>transportador</strong> o una <strong>empresa</strong> 
                    esta aplicación es para ti, ambos sectores estaran conectados de forma gratuita
                    y juntos prodran brindar servicios increibles<br/>
                </Paragraph>
                <Title level={3}>Empresa</Title>
                <Paragraph>
                    Si eres una <strong>Empresa</strong> en esta <strong>APP</strong> de forma gratuita 
                    puedes encontrar un inventario digital de mano ademas de una seccion de gestion para tus ventas 
                    locales, nunca pierdas esta información y dejanos ayudarte a guardarla y a analizarla.<br/>
                </Paragraph>
                <Title level={3}>Transportador</Title>
                <Paragraph>
                    Si eres un <strong>Transportador</strong> en esta <strong>APP</strong> podras encontrar un centro 
                    de notificaciones y solicitudes de diferentes negocios que te rodean. dejanos ayudarte a digitalizar tu 
                    negocio y acompañarte en la evolución con una aplicación que te permitira recibir y gestionar todos los envios 
                    que decidas gestionar.
                </Paragraph>
                <Button className={'btn-store'} type={'primary'} icon={<AppstoreOutlined />} shape={'round'} href={'https://play.google.com/store/apps/details?id=com.peque.mipequenegocio'}>Ir a PlayStore</Button>
            </Col>
            <Col xs={24} md={12}>
            <Title level={1}>Reem Central</Title>
                <Image src={ReemScreenShot2}/>
                <Paragraph>
                    Si eres una empresa de transporte y buscas una manera de digitalizar tu negocio y flota de vehiculos 
                    esta es tu aplicación
                </Paragraph>
                <Title level={3}>¿ De que se trata ?</Title>
                <Paragraph>
                    Muy simple, esta aplicación es el centro desde el cual puedes gestionar tu empresa de transporte y tus transportistas inscritos 
                    en <strong>REEM</strong>; una vez los transportadores individuales esten dentro de nuestra plataforma podras agruparlos en esta aplicación 
                    y revisar sus procesos ( si estan en un envio o estan en espera de uno ) como tambien la etapa del proceso 
                    ( si esta recogiendo, recogio, entrego o esta en solicitud de devolución ) 
                    
                </Paragraph>
                <Title level={3}>¿ Que objetivo tiene ?</Title>
                <Paragraph>
                    Queremos centralizar todos tus procesos y ayudarte a la gestión de tu negocio, tus procesos son nuestra prioridad
                    y queremos usar el poder de la nube para ayudarte a digitalizarlos ayudandote a tomar desiciones importantes 
                    de la mano del analisis de datos y asi ayudarte a seguir escalando tu negocio.
                </Paragraph>
                <Button className={'btn-store'} type={'primary'} icon={<AppstoreOutlined />} shape={'round'} href={'https://play.google.com/store/apps/details?id=com.app.reemcentral'}>Ir a PlayStore</Button>
            </Col>
        </Row>
    );
}

export default Apps;